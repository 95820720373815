import { useMutation } from "@tanstack/react-query";

import { ProductType } from "../../models";
import {
	FetchApiError,
	usePrimaryAPIClient,
} from "../context/PrimaryAPIClient";

export interface ISetBankIdMutationFnProps {
	contractId: string;
	contractType: ProductType;
	token: string;
}

export const useSetBankIdIdentification = () => {
	const { contractsDpsApi, contractsPpApi } = usePrimaryAPIClient();

	const mutationFn = async ({
		contractId,
		contractType,
		token,
	}: ISetBankIdMutationFnProps) => {
		if (contractType !== ProductType.Uf && contractType !== ProductType.Pf) {
			throw new Error(
				`Wrong or invalid contract type provided to useSetBankIdIdentification: ${contractType}`
			);
		}
		const request = {
			contractId,
			setAmlIdentificationRequest: { token },
		};

		if (contractType === ProductType.Uf) {
			return await contractsDpsApi.setDPSAmlIdentificationPut(request);
		} else {
			return await contractsPpApi.setPPAmlIdentificationPut(request);
		}
	};

	return useMutation<void, FetchApiError, ISetBankIdMutationFnProps, unknown>({
		mutationKey: ["setBankIdIdentification"],
		mutationFn,
	});
};
