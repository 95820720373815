import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { navigate } from "gatsby";
import { useEffect, useMemo } from "react";

import { AMLRedirect, BankIdLastActionKey } from "../../components/AML";
import { AMLForm } from "../../components/AML/Form";
import { AMLActionInfo } from "../../components/AML/identification";
import { useSmartAction } from "../../components/Contract/SmartAction/context";
import { useDrawer } from "../../components/Drawer/context";
import { DisplayableError } from "../../components/ErrorBoundary";
import { useErrorContext } from "../../components/ErrorBoundary/ErrorContext";
import { HomeButton } from "../../components/ErrorPage/GeneralMessage";
import { FetchApiError } from "../../context/PrimaryAPIClient";
import { contractLink } from "../../functions";
import { EnvironmentHelper } from "../../functions/env";
import withProtection from "../../highOrderComponents/withProtection";
import { LastAMLCheckKey, LastBankIdCheck } from "../../hooks/useAMLPrompt";
import { useUserIdentifier } from "../../hooks/useUserIdentifier";
import { useSetBankIdIdentification } from "../../queryHooks/aml";
import cs from "../../translations/cs.json";
import { CustomApiError } from "../../types/errors";

const getNavigateLinkByActionInfo = (action: AMLActionInfo | undefined) => {
	if (action === undefined || action.redirect === AMLRedirect.Default) {
		return "/smlouvy";
	} else if (action.redirect === AMLRedirect.Profile)
		return "/profil/doplneni-identifikace/";
	if (!action.contractId || !action.contractType) return "/smlouvy";
	return contractLink(
		action.contractId,
		action.contractType,
		action.redirect === AMLRedirect.Contribution ? "smlouva" : undefined
	);
};

const parseLastBankIdAction = (): AMLActionInfo | undefined => {
	if (!EnvironmentHelper.isBrowser()) return undefined;
	const lastActionString = localStorage.getItem(BankIdLastActionKey);
	return lastActionString
		? (JSON.parse(lastActionString) as AMLActionInfo)
		: undefined;
};

const AMLRedirectPage = () => {
	const user = useUserIdentifier();
	const { setDrawerContent, showDrawer } = useDrawer();
	const { setAction } = useSmartAction();
	const ai = useAppInsightsContext();
	const accessToken = useMemo(
		() => new URLSearchParams(window.location.hash).get("#access_token"),
		[]
	);
	const action = parseLastBankIdAction();
	const { setError } = useErrorContext();

	const { mutateAsync, error: mutationError } = useSetBankIdIdentification();

	const actionCallback = ({
		action,
		success,
		errorMessage,
	}: {
		action: AMLActionInfo;
		success: boolean;
		errorMessage?: string;
	}) => {
		void navigate(getNavigateLinkByActionInfo(action));
		if (action.redirect === AMLRedirect.Contribution) {
			setAction({
				type: "FondBankId",
				context: {
					success,
					errorMessage,
				},
			});
		} else if (action.redirect === AMLRedirect.Strategy) {
			setAction({
				type: "InvestmentStrategyBankId",
				context: {
					success,
					errorMessage,
				},
			});
		} else {
			localStorage.setItem(LastAMLCheckKey, Date.now().toString());
			setDrawerContent(
				<AMLForm
					contractId={action.contractId}
					contractType={action.contractType}
				/>
			);
			showDrawer();
		}
	};

	useEffect(() => {
		void (async () => {
			try {
				if (!action) {
					throw new Error("Missing bankID action from local storage");
				}
				if (typeof accessToken !== "string")
					throw new Error("Missing access token from bank id redirect");
				const { contractId, contractType } = action;

				await mutateAsync(
					{ contractId, contractType, token: accessToken },
					{
						onSuccess: () => {
							actionCallback({ action, success: true });
						},
						onError: (error) => {
							throw error;
						},
					}
				);
			} catch (err) {
				const isFetchError = err instanceof FetchApiError;
				if (!isFetchError) {
					ai.trackEvent({
						name: "Bank ID validation frontend error.",
						properties: {
							reason: (err as Error).message,
							errName: (err as Error).name,
							contractId: action?.contractId ?? "Missing contract id",
							user,
						},
					});
				}

				const errorMessage = (() => {
					if (isFetchError) {
						switch (err.errorResponse.code) {
							case CustomApiError.ValidationFailed:
								return cs.AML.bankIdErrorMessages.bankIdWrongBirthNumber;
							case CustomApiError.UnprocessableEntity:
								return err.errorResponse.message;
						}
					}
					return cs.AML.bankIdErrorMessages.bankIdValidationError;
				})();

				if (action && action.redirect !== AMLRedirect.Default) {
					actionCallback({ action, success: false, errorMessage });
				} else {
					setError(
						new DisplayableError(
							"Bank ID validation failed.",
							{
								message: errorMessage,
								customButton: <HomeButton />,
							},
							true
						)
					);
				}
			}
			localStorage.setItem(LastBankIdCheck, Date.now().toString());
			localStorage.removeItem(BankIdLastActionKey);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <>{cs.AML.loadingBankId}</>;
};

export default withProtection(AMLRedirectPage);
